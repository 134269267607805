import React from 'react';
import * as S from './CountDown.styles';
import useCountdown from '../../hooks/useCountdown';

const CountDown = props => {
  const {
    format,
    endMonth,
    endDay,
    endYear,
    endHour,
    endMinutes,
    endTimezone,
    timeoutString,
    design = 'design1',
    dayTitle,
    hourTitle,
    minTitle,
    secTitle,
    customText
  } = props;

  const {
    remainingTime,
    days,
    hours,
    minutes,
    seconds,
    loading
  } = useCountdown({
    endMonth,
    endDay,
    endYear,
    endHour,
    endMinutes,
    endSeconds: 0,
    endTimezone
  });

  const getLabels = () => {
    // labels
    const ddLabel = days > 1 ? 'days' : 'day';
    const hhLabel = hours > 1 ? 'hours' : 'hour';
    const mmLabel = minutes > 1 ? 'minutes' : 'minute';
    const ssLabel = seconds > 1 ? 'seconds' : 'second';

    return {
      ddLabel,
      hhLabel,
      mmLabel,
      ssLabel
    };
  };

  const CustomText = () => {
    let currentText = customText;
    currentText = currentText?.replace(`[DD]`, days);
    currentText = currentText?.replace(`[HH]`, hours);
    currentText = currentText?.replace(`[MM]`, minutes);
    currentText = currentText?.replace(`[SS]`, seconds);

    const { ddLabel, hhLabel, mmLabel, ssLabel } = getLabels();
    currentText = currentText?.replace(`[DDLABEL]`, ddLabel);
    currentText = currentText?.replace(`[HHLABEL]`, hhLabel);
    currentText = currentText?.replace(`[MMLABEL]`, mmLabel);
    currentText = currentText?.replace(`[SSLABEL]`, ssLabel);

    return <div dangerouslySetInnerHTML={{ __html: currentText }} />;
  };

  const Output = () => {
    if (remainingTime > 0 || loading) {
      switch (format) {
        case 'CUSTOM-TEXT':
          return <CustomText />;
        case 'DD:HH:MM:SS':
          return (
            <>
              <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:
              <span>{seconds}</span>
            </>
          );
        case 'DD:HH:MM:SS-WITH-TEXT':
          return (
            <>
              <S.CountItem>
                <span>{remainingTime > 0 ? days : '..'}</span>
                {dayTitle}
              </S.CountItem>
              <span className="dots">:</span>
              <S.CountItem>
                <span>{remainingTime > 0 ? hours : '..'}</span>
                {hourTitle}
              </S.CountItem>
              <span className="dots">:</span>
              <S.CountItem>
                <span>{remainingTime > 0 ? minutes : '..'}</span>
                {minTitle}
              </S.CountItem>
              <span className="dots">:</span>
              <S.CountItem>
                <span>{remainingTime > 0 ? seconds : '..'}</span>
                {secTitle}
              </S.CountItem>
            </>
          );
        case 'DD:HH:MM':
          return (
            <>
              <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>
            </>
          );
        case 'HH:MM':
          return (
            <>
              <span>{hours}</span>:<span>{minutes}</span>
            </>
          );
        case 'MM:SS':
          return (
            <>
              <span>{minutes}</span>:<span>{seconds}</span>
            </>
          );
        default:
        case 'HH:MM:SS':
          return (
            <>
              <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
            </>
          );
      }
    } else {
      return timeoutString || 'Promo Ended';
    }
  };

  return (
    <S.Container design={design}>
      <Output />
    </S.Container>
  );
};

export default CountDown;
