import { useEffect, useState } from 'react';
import { convertTZ } from '../utils/datetime';

const leadingZero = input => {
  if (String(input).length === 1) {
    return '0' + String(input);
  }
  return String(input);
};

const useCountdown = props => {
  const {
    endMonth = 1,
    endDay = 1,
    endYear = 1970,
    endHour = 0,
    endMinutes = 0,
    endSeconds = 0,
    endTimezone = 'Europe/London',
    interval = 1000
  } = props;

  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState({
    remainingTime: 0,
    days: `00`,
    hours: `00`,
    minutes: `00`,
    seconds: `00`,
    loading
  });

  // promoEnd is set to UTC format then converted to the desired endTimezone
  const promoEnd = `${endYear}-${leadingZero(endMonth)}-${leadingZero(
    endDay
  )}T${leadingZero(endHour)}:${leadingZero(endMinutes)}:${leadingZero(
    endSeconds
  )}Z`;
  const endTime = convertTZ(promoEnd, endTimezone).getTime();

  useEffect(() => {
    setLoading(false);
    const intervalRef = setInterval(() => {
      // new Date() is in local GTM, converted to the desired endTimezone
      const currentTime = convertTZ(new Date(), endTimezone).getTime();
      const remainingTime = endTime - currentTime;

      const days = leadingZero(
        Math.floor(remainingTime / (1000 * 60 * 60 * 24))
      );
      const hours = leadingZero(
        Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      const minutes = leadingZero(
        Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60))
      );
      const seconds = leadingZero(
        Math.floor((remainingTime % (1000 * 60)) / 1000)
      );

      setResult({
        remainingTime,
        days,
        hours,
        minutes,
        seconds,
        loading
      });

      if (remainingTime < 0) {
        clearInterval(intervalRef);
        return;
      }
    }, interval);

    return () => clearInterval(intervalRef);
  }, [interval, endTimezone, endTime, setResult, loading, setLoading]);

  return result;
};

export default useCountdown;
