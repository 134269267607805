import styled from 'styled-components';

export const Container = styled.span`
  & > span {
    margin: 0 8px;
  }

  ${({ design }) => {
    switch (design) {
      case 'halloween':
        return `
          & {
          display: flex;
          align-items: baseline;
          color: #000B2D;
          }
          & span {
          margin: 0 4px;
          font-weight: 700;
          color: #000B2D;
          }
        `;
      case 'black-friday':
        return `
          & {
          display: flex;
          align-items: center;
          text-align: center;
          gap: 10px;
          
          @media (max-width: 991px) {
            gap: 0px;
          }
          }
          & div {
          display: flex;
          flex-direction: column;
          background: transparent;
          backdrop-filter: none;
          font-size: 14px;
          color: #FFFFFF;
          padding: 0;
          
          @media (max-width: 991px) {
             flex-direction: row;
             align-items: center;
          }
          }
          & span {
          font-weight: 700;
          font-size: 28px !important;
          
          @media (max-width: 991px) {
             margin: 0 4px 0 0;
             font-size: 16px !important;
          }
          }
          
          & .dots {
          display: none;
          
          @media (max-width: 991px) {
             display: block;
             margin: 0 4px;
          }
          }
        `;
      case 'christmas-2023':
        return `
          & {
          display: flex;
          align-items: center;
          text-align: center;
          gap: 8px;
          
          & .dots {
          display: none;
          }
          
          @media (max-width: 641px) {
            gap: 4px;
          }
          
          & div {
          display: flex;
          flex-direction: column;
          background: #2F0057;
          backdrop-filter: none;
          font-size: 14px;
          color: #FFFFFF;
          padding: 8px;
          
          @media (max-width: 641px) {
             flex-direction: row;
             align-items: center;
             font-size: 12px;
             padding: 3px 6px;
          }
          }
          
          & span {
          font-weight: 700;
          font-size: 20px !important;
          
          @media (max-width: 641px) {
             margin: 0 4px 0 0;
             font-size: 16px !important;
          }
          }
        }
        `;
      default:
        return ``;
    }
  }}
`;

export const CountItem = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);

  color: #000b2d;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-size: 12px;

  & > span {
    line-height: 100%;
    font-size: 16px;
    font-weight: 700;
  }
`;

export const Loading = styled.span`
  font-size: 14px;
  line-height: 14px;
`;
